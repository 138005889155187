<template>
    <div class="content">
        <left-bar activePathKey="enterCompany"></left-bar>
        <div class="enterprise-list" v-loading="loading">
            <div v-if="!listData.length" style="background:#FFF">
                <div class="title-bar">企业认证</div>
                <div style="padding:140px 0 160px 0;font-size:20px;font-weight:600;">
                    您还没有企业，请先认证
                </div>
                <div style="padding-bottom:160px;">
                    <el-button type="primary" style="width: 160px;font-size:16px;padding:12px 0;background:#007EF2"
                        @click="toEnterCompany">认证</el-button>
                </div>
            </div>

            <div v-else>

                <div class="header-bar">
                    <span style="font-size:20px;font-weight:600">企业认证</span>
                    <el-button plain size="small" type="primary" @click="toEnterCompany" class="settle-btn">
                        认证
                    </el-button>
                </div>

                <el-table :data="listData" header-cell-class-name="header-style" height="500px" empty-text="暂无数据"
                    @filter-change="handleFilterChange">
                    <el-table-column label="内容" align="center">
                        <template slot-scope="{row}">
                            <div style="text-align:left;padding:8px;">
                                <div style="font-weight:bold;font-size:20px;color:#212121">
                                    {{ row.name }}
                                </div>
                                <div style="font-size:14px;color:#888;margin-top:20px">入驻时间：{{ row.settledTime || "-" }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="filterNameValue || '全部状态'" align="left" width="100px" :filters="stateList"
                        :filter-multiple="false" column-key="stateFilter" :filter-method="filterList">
                        <template slot-scope="{row}">
                            <span style="font-size:16px" :style="{ color: row.auditStatus === 0 ? '#EE1E1E' : '#212121' }">
                                {{ stateList[row.auditStatus].text }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="详情" align="center" width="100px" fixed="right">
                        <template slot-scope="{row}">
                            <el-button type="text" style="font-size:16px" @click="toEnterpriseDetail(row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>


<script>
import { getMyEnterpriseList } from "@api/personalCenter"
import leftBar from '@/views/personalCenter/components/left-bar/left-bar'
export default {
    components: {
        leftBar
    },
    data() {
        return {
            listData: [],
            loading: false,
            stateList: [
                { text: "审核失败", value: 0, },
                { text: "审核成功", value: 1, },
                { text: "审核中", value: 2, },
            ],
            filterNameValue: "",
        }
    },
    mounted() {
        document.title = "邦建通-个人中心";
        this.getMyEnterpriseList()
    },
    methods: {
        async getMyEnterpriseList() {
            try {
                this.loading = true
                let { data } = await getMyEnterpriseList()
                this.listData = data
                this.loading = false
            } catch (err) {
                this.loading = false
            }
        },
        handleFilterChange(filters) {
            let filterArr = filters['stateFilter']
            if (filterArr.length) {
                this.filterNameValue = this.stateList[filterArr[0]].text
            } else {
                this.filterNameValue = ''
            }

        },
        filterList(value, row) {
            return row.auditStatus === value
        },
        toEnterCompany() {
            this.$router.push("/enterCompany/enterCompany")
        },
        toEnterpriseDetail(row) {
            this.$router.push({
                path: "/enterCompany/enterCompany",
                query: {
                    id: row.id
                }
            },
            )

        },
    }
}


</script>
<style>
.header-style {
    color: #212121;
    font-size: 16px;
    font-weight: 400 !important;
    background: #F8FDFF !important;
    color: #212121;
}
</style>

<style scoped lang="less">
.content {
    background: #F8F9FB;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    font-family: Source Han Sans CN-Regular;
    color: #212121;
}

.enterprise-list {
    width: 1260px;

    .header-bar {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }

    .title-bar {
        font-size: 16px;
        border-bottom: 1px solid #DCDCDC;
        padding: 16px 20px 24px 20px;
        text-align: left;
        font-weight: 600;
    }

    .settle-btn {
        width: 68px;
        background: #fff;
        padding: 7px 0;
        font-size: 14px;
        border-color: #007EF2;
        color: #007EF2;
        border-radius: 4px
    }
}
</style>